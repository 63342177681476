export const TEXT_FLOAT_OPTIONS = {
  duration: '80%',
  hook: 0.3,
  height: 20
};

export const PREVIEW_TEXT =
`오른쪽 귓불 아래쪽으로 ‘바삭’ 하는, 큰 소리가 들렸다.
선풍기 바람에 휩쓸려 바닥에 날아다니는 방 안의 비닐봉
지, 과자 포장지들과는 분명 다른 느낌의 소리, 그리고 그
것들에게는 도저히 날 수 없는 것 같은 묵직한 소음과 축
축한 감각이 느껴져 황급히 몸을 일으켰다.
  무언가 먹먹한 느낌이 남아 있는 오른쪽 귀를 손으로
가볍게 문질렀다. 머리를 대고 누웠던 베개 위에는 아무
것도 없었고 바삭거리는 이상한 소리도 더 이상 들리지
않았다. 고요한 방 안에 선풍기가 달달달 소리를 내며 얼
굴을 천천히 가로저을 뿐이었다. 그제 저녁에 먹고 책상
에 놓아둔 포테이토칩의 연두색 껍질이 침대 바로 앞까지
밀려와 있었다. 잠결에 착각한 게 분명하다고 생각하며
얼굴을 짝짝 때리고 다시 베개 위로 머리를 누이려는 찰
나, 나는 ‘그것’을 발견했다. 하얀색 베개 정중앙에 놓여
있는 작고 가느다란 ‘ᄀ’자 모양의 검은색 물체. 머리맡에
놓인 취침 등을 켜고 그 물체를 조심스럽게 들어 올렸다.
얇지만 단단한 재질의 그것이 내 머리카락도, 내 옷 어딘`;
