
import React from 'react';

import { Link, GatsbyLinkProps } from 'gatsby';

const styles = require('./Button-styles.module.css');

interface Props {
  size?: string;
  color?: string;
  noOpacity?: boolean;
  className?: string;
  children?: React.ReactNode;
}

function createClassesFromProps(props: Props) {
  const { size, color, noOpacity, className } = props;
  const classes = [styles.button];
  if (size === 'small') {
    classes.push(styles.sizeSmall);
  }
  if (color === 'blue') {
    classes.push(styles.colorBlue);
  }
  if (color === 'blue-bg') {
    classes.push(styles.colorBlueBg);
  }
  if (color === 'white') {
    classes.push(styles.colorWhite);
  }
  if (color === 'gray') {
    classes.push(styles.colorGray);
  }
  if (noOpacity) {
    classes.push(styles.noOpacity);
  }
  className && classes.push(className);
  return classes.join(' ');
}

export default function Button(props: Props & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { children, ...anchorProps } = props;
  return <a {...anchorProps} className={createClassesFromProps(props)}>{children}</a>;
}

export function LinkButton(props: Props & GatsbyLinkProps<any>) {
  const { size, color, className, children, ref, noOpacity, ...linkProps } = props;
  return <Link {...linkProps} className={createClassesFromProps(props)}>{children}</Link>;
}
